<script setup lang="ts">
import type { Schemas } from "#shopware";

const compareSidebarController = useProductCompareSidebarController();

const { items, count } = useLocalCompareList();
const { getProductsByIds } = useProductHelper();
const products = ref<Schemas["Product"][]>([]);
const isLoading = ref(false);
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const loadProductsByItemIds = async (itemIds: string[]): Promise<void> => {
  isLoading.value = true;

  try {
    products.value = await getProductsByIds(itemIds || items.value);
  } catch (error) {
    console.error("[getProductsByIds]", error);
  }

  isLoading.value = false;
};

watch(
  items,
  (items) => {
    loadProductsByItemIds(items);
  },
  { immediate: true },
);
</script>

<template>
  <LayoutSidebar
    :controller="compareSidebarController"
    side="right"
    layer-class="z-1500 xl:z-10"
    class="bg-scheppach-primary-50! flex overflow-scroll"
  >
    <div
      class="xl:mb-11 xl:mt-22.5 px-5 py-6.5 xl:p-10 text-left xl:bg-[#336d9d] flex text-scheppach-primary-700 xl:text-scheppach-shades-0 items-center"
    >
      <div class="text-5 xl:text-6 font-700 uppercase flex-grow">
        {{ $t("product.compare") }}
      </div>
      <button
        type="button"
        class="size-4 xl:size-5 i-sl-close cursor-pointer"
        @click="compareSidebarController.close"
      ></button>
    </div>
    <aside class="px-5 xl:px-10.5 flex justify-center items-center">
      <ul
        v-if="count > 0"
        role="list"
        class="flex flex-col gap-2 p-0 m-0 relative"
      >
        <li
          v-for="(product, index) in products"
          :key="product.id"
          class="block mb-2"
        >
          <ProductListItem :product="product" />
        </li>
      </ul>
      <div v-else class="text-2xl text-center">
        {{ $t("cart.emptyCompareLabel") }}
      </div>
    </aside>
    <aside
      class="flex mx-5 xl:mx-10.5 my-6.5 xl:my-8 gap-8 justify-end items-center"
    >
      <NuxtLink
        v-if="count > 0"
        :to="formatLink('/compare')"
        class="text-scheppach-primary-500 font-700 custom-underline lh-5"
        @click="compareSidebarController.close"
      >
        {{ $t("product.viewCompare") }}
      </NuxtLink>
    </aside>
  </LayoutSidebar>
</template>
